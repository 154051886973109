import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IdGuard implements CanActivate {

  env = environment;

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    // Liste des sous-domaines autorisés pour ces routes
    const allowedSubdomains = ['id'];

    if (subdomain != this.env.domainRevendeur) {
      return true; // Autorisé
    }

    // Sinon, redirige vers une autre page (exemple : page d'accueil ou 404)
    return this.router.createUrlTree(['/login']);
  }
}
