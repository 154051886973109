import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class OnatiTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private apiUrl: string) {}

  getTranslation(lang: string): Observable<any> {
    const url = `${this.apiUrl}languages/${lang}`;
    return this.http.get(url);
  }
}
