import { Component, OnInit } from '@angular/core';
import { CustomerFileService } from '../../shared/services/model/customerFile/customer-file.service';
import { ConsoleService } from '../../shared/services/console/console.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-greatings',
  templateUrl: './greatings.component.html',
  styleUrl: './greatings.component.scss'
})
export class GreatingsComponent implements OnInit {
  translateParams: any = {};
  token;

  constructor(
    private activatedRoute: ActivatedRoute,
    public customerFileService: CustomerFileService,
    private _consoleService: ConsoleService
  ){

  }

  ngOnInit(): void {
    const firstname = this.customerFileService?.customerFile?.firstname;
    this.translateParams = { name: firstname };
    this._consoleService.debug('GreatingsComponent - ngOnInit', 'b', 'firstname', firstname);
  }
}
