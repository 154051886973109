import { Injectable } from '@angular/core';
import { sidebarSignal } from '../../signals/sidebar.signal';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  // Exposez le signal
  sidebarVisible = sidebarSignal;

  constructor() {}

  show() {
    console.log('show');
    this.sidebarVisible.set(true);
  }

  hide() {
    this.sidebarVisible.set(false);
  }
}
