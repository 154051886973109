<div class="container-fluid d-flex align-items-center justify-content-center text-center bg-vini-primary" style="height: 100dvh;">
    <div class="card border-0 my-4 p-0">
        <div class="card-body bg-vini-primary d-flex justify-content-around align-items-center h-100">
          <div>
            <div class="mb-5">
                <img src="assets/images/logo_vini.png" alt="Logo Vini" style="width: 100px;">

                <h1 class="text-white">
                    Espace<br>Revendeur
                </h1>
            </div>
            <div>
                <div class="mb-2 w-100">
                    <input type="text" id="login" class="form-control p-2 w-100" placeholder="Login" [(ngModel)]="loginField" pInputText/>
                </div>
                <div class="mb-2 w-100">
                    <input type="password" id="password" class="form-control p-2 w-100" placeholder="Mot de passe" [(ngModel)]="passwordField" pInputText/>
                </div>
            </div>
            <a class="btn bg-vini-primary-dark text-white w-100 my-4" (click)="login()">
                Valider
            </a>

          </div>
        </div>
    </div>
</div>
