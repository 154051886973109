<h2 class="my-5 fs-3 text-center language-rotator" [class.fade-out]="langSelected">
  <span class="lang-text">Choisissez votre langue</span>
  <span class="lang-text">Choose your language</span>
  <span class="lang-text">A maiti<br/> i te reo</span>
</h2>
<ul class="list-group w-100 m-0 p-0">
  <li *ngFor="let lang of languages; let i = index"
      class="list-group-item fs-4 text-start"
      [style.animationDelay]="i * 0.2 + 's'"
      [class.slide-out]="slideOutStates[i]"
      [class.language-item]="!slideOutStates[i]"
      (click)="storeLanguage(lang.value, i)">

    <img [src]="lang.flag" class="flag-image" [alt]="'Drapeau ' + lang.name">
    {{ lang.name }}
  </li>
</ul>
