import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { IdentificationComponent } from './pages/identification/identification.component';
import { ScanQrcodeComponent } from './pages/identification/scan-qrcode/scan-qrcode.component';
import { GreatingsComponent } from './pages/greatings/greatings.component';
import { RevendeurComponent } from './pages/revendeur/revendeur.component';
import { RevendeurGuard } from './shared/guard/revendeur/revendeur.guard';
import { IdGuard } from './shared/guard/id/id.guard';
import { AuthGuard } from './shared/guard/auth/auth.guard';
import { JeuComponent } from './pages/jeu/jeu.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent
  },
  {
    path: 'jeudemat',
    component: JeuComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'felicitations/:token',
    component: GreatingsComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'identification',
    component: IdentificationComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'identification/:token',
    component: IdentificationComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'scaning-qrcode',
    component: ScanQrcodeComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'scaning-qrcode/:token?',
    component: ScanQrcodeComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'onboarding/:token?',
    component: OnboardingComponent,
    canActivate: [IdGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [RevendeurGuard]
  },
  {
    path: 'revendeur',
    component: RevendeurComponent,
    canActivate: [AuthGuard, RevendeurGuard]
  },
  {
    path: 'mentions-legales',
    component: MentionsLegalesComponent,
    canActivate: [IdGuard]
  },
  {
    path: ':token',
    component: WelcomeComponent,
    canActivate: [IdGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
