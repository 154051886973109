import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';

import { AnimationOptions } from 'ngx-lottie';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { ConsoleService } from '../../shared/services/console/console.service';
import { CustomerFileService } from '../../shared/services/model/customerFile/customer-file.service';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent {

  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _consoleService: ConsoleService,
    public customerFileService: CustomerFileService,
  ) {
    localStorage.setItem('stepper', "0");
    this.activatedRoute.paramMap.subscribe(params => {
      const routeToken = params.get('token');
      this.token = routeToken || localStorage.getItem('token');  // Si le paramètre token n'existe pas, on utilise celui dans localStorage
      this._consoleService.debug('OnboardingComponent - constructor', 'o', 'this.token', this.token);

      if(this.token && this.token.length === 32) {
        localStorage.setItem('token', this.token);
      } else {
        localStorage.removeItem('token');
      }
      this.customerFileService.initCustomerFile();
    });

  }


}
