import { Component } from '@angular/core';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {

  constructor(public notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationService.setDefaultNotification();
  }
}
