<div class="" style="height: 100dvh; width: 100dvw; position: absolute; top: 0; left: 0; background-color: #F6F5F9; overflow:hidden;">
  <header class="header revendeur d-flex align-items-end justify-content-center text-center pb-5 position-relative" style="z-index: 99;">
    <a [routerLink]="'/'"><img src="assets/images/logo_vini.png" class="logo-vini" alt="Logo Vini" style="width: 50px; position: fixed; top: 15px; left: 15px;"></a>
    <!-- <div (touc   -->
    <button class="btn bg-none position-fixed" style="top: 15px; right: 15px;" (click)="sidebarService.show()"><i class="pi pi-bars fs-2 text-white"></i></button>
    <div #steppers class="steppers rounded-pill d-flex justify-content-between align-items-center shadow slideInTopAnimation" [class.active-1]="active == 0" [class.active-2]="active == 1" [class.active-3]="active == 2">
      <div #firstStepper class="stepper active">
        {{ 'step_1_title' | translate }}
      </div>
      <span id="slider" class="slider"></span>
    </div>
  </header>
  <section class="identificationContent">
    <app-form-id [revendeur]="true"></app-form-id>
  </section>
</div>

<app-sidebar (showLanguageForm)="showLanguageForm = true" [isRevendeur]="true"></app-sidebar>
<app-notification></app-notification>
