import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ConsoleService } from '../console/console.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {


  key;

  constructor(
    private _consoleService: ConsoleService
  ) { }

  checkIfMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

    // Combiner la vérification de la taille de l'écran et du userAgent
    if (/android|iPhone|iPad|iPod/i.test(userAgent) || window.innerWidth <= 768) {
      return true;
    }

    return false;
  }

  ageValidator(minAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      // Vérifier si la valeur est vide
      if (!value) {
        return null; // Le validateur requis gère les valeurs vides
      }

      // Vérifier si la valeur est une chaîne au format 'YYYY-MM-DD'
      let birthDate: Date;
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
        const [year, month, day] = value.split('-').map(Number);
        birthDate = new Date(year, month - 1, day); // Mois dans Date commence à 0
      } else if (value.year && value.month && value.day) {
        // Si la valeur est au format NgbDateStruct
        const { year, month, day } = value;
        birthDate = new Date(year, month - 1, day);
      } else {
        return { ageInvalid: true }; // Format de date invalide
      }

      // Calculer l'âge
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      // Retourner null si l'âge est valide, sinon retourner l'erreur
      return age >= minAge ? null : { ageInvalid: true };
    };
  }


  formatDateToISO(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }


  encryptUsingAES256(name) {
    if(!name) return;
    let _key = CryptoJS.enc.Utf8.parse(this.key);
    let _iv = CryptoJS.enc.Utf8.parse(this.key);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(name), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }
}
