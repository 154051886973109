import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  loginField: string | undefined;
  passwordField: string | undefined;
  email: string = '';
  password: string = '';

  constructor(
    private _router : Router,
    private _authService: AuthService
  ){

  }
  ngOnInit(): void {
    if(this._authService.isAuthenticated()) this._router.navigate(["/revendeur"]);
  }

  login(){
    this._authService.login({email: this.loginField, password: this.passwordField});
  }
}
