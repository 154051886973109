
<div class="bg-white d-flex justify-content-center align-items-center" style="height: 100dvh; width: 100dvw;">
  <div class="p-5">
      <h1 class="text-center mb-4 text-vini-primary">
        Mentions légales
      </h1>

      <h2>Editeur</h2>
      <p><strong>ONATi</strong>, SAS au capital social de 5 122 000 000 XPF</p>
      <p>RCS Papeete N° 18359 B</p>
      <p>N° Tahiti : D01975</p>
      <p>Siège social : Rond-point de la base marine - Fare Ute - BP 440 - 98713 PAPEETE</p>
      <p>Téléphone : 39 50</p>
      <p><a href="mailto:contact@vini.pf">Nous contacter par email</a></p>
      <h2>Directeur de la publication</h2>
      <p>Christophe Bergues</p>
      <h2>Hébergement</h2>
      <p>L’Office des Postes et des Télécommunications</p>
      <p>Siège social : Rond-point de la base marine – Fare Ute – BP 440 - 98713 PAPEETE – Tahiti – Polynésie
        française</p>
      <p>Téléphone : 40 48 77 00</p>
  </div>
</div>
