import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RevendeurGuard implements CanActivate {

  env = environment;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // if (this.authService.isAuthenticated()) {
    //   return true;
    // }

    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if (subdomain == this.env.domainRevendeur) {
      return true;
    } else {
      return this.router.navigate(['/']);
    }
  }
}
