import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerFileService } from './shared/services/model/customerFile/customer-file.service';
import { SettingService } from './shared/services/model/setting/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'app-id';

  constructor(
    private _translateService: TranslateService,
    private _customerFileService: CustomerFileService,
    private _settingService: SettingService
  ) {
  }

  ngOnInit() {
    this._settingService.all();
    // this._customerFileService.initCustomerFile();
    localStorage.getItem('language') ? this._translateService.use(localStorage.getItem('language')) : this._translateService.use('fr');
  }

}
