import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolsService } from '../../shared/services/tools/tools.service';
import { ConsoleService } from '../../shared/services/console/console.service';
import { ApiService } from '../../shared/services/api/api.service';
import { NotificationService } from '../../shared/services/notification/notification.service';

@Component({
  selector: 'app-jeu',
  templateUrl: './jeu.component.html',
  styleUrl: './jeu.component.scss'
})
export class JeuComponent {
  customerForm: FormGroup;
  minDate: any; // Replace with a specific date or calculated value
  maxDate: any; // Replace with a specific date or calculated value
  submitted: boolean = false;

  ShowGreatings: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _toolsService: ToolsService,
    private _consoleService: ConsoleService,
    private _apiService: ApiService,
    private _notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.initForm();

    const today = new Date();

    this.minDate = {
      year: today.getFullYear() - 100,
      month: today.getMonth() + 1, // Month is 0-based in JS
      day: today.getDate(),
    };

    this.maxDate = {
      year: today.getFullYear() - 18,
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
  }

  // Initialize form with validation rules
  private initForm(): void {
    this.customerForm = this.fb.group({
      lastname: ['', [Validators.required, Validators.pattern(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s-]+$/)]],
      firstname: ['', [Validators.required, Validators.pattern(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s-]+$/)]],
      birthdate: ['', [Validators.required, this._toolsService.ageValidator(18)]],
      phone: ['', [Validators.required, Validators.pattern(/^(?:\+689|689)?(40|87|88|89)[0-9]{6}$/)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // Check if a form field is invalid
  isFieldInvalid(fieldName: string): boolean {
    const field = this.customerForm.get(fieldName);
    return !!(field && field.invalid && (field.dirty || field.touched || this.submitted));
  }

  onSubmit(): void {
    if (this.customerForm.invalid) {
      this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'customerForm', this.customerForm.errors);
      return;
    }

    const formValues = this.customerForm.value;
    this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'formValues', formValues);

    // Formater la date de naissance au format YYYY-MM-DD
    const birthdate = `${formValues.birthdate.year}-${String(formValues.birthdate.month).padStart(2, '0')}-${String(formValues.birthdate.day).padStart(2, '0')}`;

    const data = {
      phone: formValues.phone,
      email: formValues.email,
      lastname: formValues.lastname,
      firstname: formValues.firstname,
      birthdate: birthdate,
    };

    this._apiService.post('prize-participants', data).subscribe({
      next: (response) => {
        this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'API Success Response', response);
        this.ShowGreatings = true;
      },
      error: (error) => {
        this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'API Error Response', error);

        if (error.status === 409) {
          this._notificationService.showNotification("Mhmmmmm !", "Il semblerait que vous ayez déjà participé à ce jeu.");
        } else {
          this._notificationService.showNotification("generic_error_title", "generic_error_description");
        }
      },
    });
  }


  // Reset form
  resetForm(): void {
    this.customerForm.reset();
    this.submitted = false;
  }
}
