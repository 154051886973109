import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleService } from '../console/console.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  title: string;
  content: string;
  show: boolean = false;
  showButton: boolean = false;
  @Output() callbackNotification = new EventEmitter<string>();

  constructor(
    public translateService: TranslateService,
    private _consoleService: ConsoleService
  ) { }

  showNotification(title: string, content: string, callback: string = null) {
    this.title = title;
    this.content = content;
    this.showButton = true;
    this.show = true;
    this.callbackNotification.emit(callback);
  }

  setDefaultNotification() {
    this.translateService.get(['generic_error_title', 'generic_error_description']).subscribe(translations => {
      this.title = translations['generic_error_title'];
      this.content = translations['generic_error_description'];
    });
  }

  showDefault() {
    this.setDefaultNotification();
    this.showButton = true;
    this.show = true;
  }

  showLoading() {
    this.translateService.get(['loading_title', 'loading_description']).subscribe(translations => {
      this._consoleService.debug('NotificationService - showLoading', 'b', 'translations', translations);
      this.title = translations['loading_title'];
      this.content = translations['loading_description'];
      this.showButton = false;
      this.show = true;
    });

  }

  hideNotification() {
    this.show = false;
  }

  isShow() {
    return this.show;
  }
}
