import { Component } from '@angular/core';
import { SidebarService } from '../../shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-revendeur',
  templateUrl: './revendeur.component.html',
  styleUrl: './revendeur.component.scss'
})
export class RevendeurComponent {
  showLanguageForm: boolean = false;
  active: number = 0;

  constructor(
    public sidebarService: SidebarService,
  ) { }

  ngOnInit(): void {
  }
}
