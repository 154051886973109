import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './../api/api.service';
import { Router } from '@angular/router';
import { ConsoleService } from '../console/console.service';
import { environment } from '../../../../environments/environment'
import { CookiesService } from '../cookies/cookies.service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // baseUrl = ["","/sample-page","/"];
  defaultUrl = "/dashboard"

  user: any;
  loginSuccess: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  env = environment;

  constructor(
    private _api: ApiService,
    private _http: HttpClient,
    private _router: Router,
    private _cookiesService: CookiesService,
    private _consoleService : ConsoleService,
  ) { }

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
    this._cookiesService.clear();
    this._router.navigate(['/']);

  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  id(): any {
    return this._cookiesService.get('id');
  }


  redirect() {
    (this.isAuthenticated) ? this._router.navigateByUrl(this.defaultUrl) : this._router.navigateByUrl('/');
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    this._consoleService.debug("AuthService - isAuthenticated", "g","tokenBearer", this._cookiesService.get('tokenBearer'));
    return this._cookiesService.get('tokenBearer') ? true : false;
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAdmin(): boolean {
    return (this._cookiesService.get('user_type_id') == '1') ? true : false;
  }

  /**
   * retrieve user authenticated
   * @return user object
   */
  authenticated(): any {
    let user = this._cookiesService.get('user');
    return user ? JSON.parse(user):null;
  }

  checkIfOrderIsPermitted(){
    let idsPermitted = [5,6,7,8,9,10,11,12,13];
    let me = this.authenticated();
    if(me != null && idsPermitted.indexOf(me.id) > -1) return true;
    return false;
  }

  login(data): void {
    this._consoleService.debug("AuthService - login", "g","API_URL", API_URL);
    this._http.post(API_URL+'login', data).subscribe((data: any) => {
      this.setCookies(data.data);
      // localStorage.setItem('baseUrl', this.baseUrl[data.user_type_id]);
      // this._api.setHeadersBasicOptions();
      // this._api.setHeadersPaginateOptions('5');
      this._consoleService.debug("AuthService - login", "g","data", data);
      this._router.navigate(["/revendeur"]);
    },
    err => {
      this.loginSuccess.emit(false);
      // this._notifier.notify( "error", "Erreur sur vos codes d'accès" );
    });
  }

  setCookies(data) {
    this.user = data.user;
    this._consoleService.debug("setCookies", "g", "login DATA", this.user);
    this._cookiesService.set('tokenBearer', data.token);
    this._cookiesService.set('user', JSON.stringify(this.user));
  }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): any {
    this.clear();
  }
}
