import { NgModule } from '@angular/core';

import { StepsModule } from 'primeng/steps';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { StepperModule } from 'primeng/stepper';
import { InputTextModule } from 'primeng/inputtext';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputOtpModule } from 'primeng/inputotp';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  declarations: [],
  imports: [
    StepsModule,
    ButtonModule,
    FloatLabelModule,
    DropdownModule,
    CalendarModule,
    StepperModule,
    InputTextModule,
    ToggleButtonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ToastModule,
    SidebarModule,
    MenuModule,
    CheckboxModule,
    DialogModule,
    CarouselModule
  ],
  exports: [
    StepsModule,
    ButtonModule,
    FloatLabelModule,
    DropdownModule,
    CalendarModule,
    StepperModule,
    InputTextModule,
    ToggleButtonModule,
    IconFieldModule,
    InputIconModule,
    InputOtpModule,
    ToastModule,
    SidebarModule,
    CheckboxModule,
    MenuModule,
    DialogModule,
    CarouselModule
  ],
  providers: [MessageService]
})
export class PrimengModule { }
