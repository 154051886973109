<div style="height: 100dvh; width: 100dvw; position: absolute; top: 0; left: 0;">
  <div class="d-flex align-items-center justify-content-center flex-column text-center bg-vini-primary" style="height: 100dvh;;">
    <div class="animation-container" style="margin-top: -140px;">
      <!-- <ng-lottie [options]="options" class="lottie-animation" (animationCreated)="onAnimationCreated($event)"></ng-lottie> -->
       <img src="assets/images/logo_vini.png" alt="Welcome" style="max-width: 120px;">
    </div>
    <div>
      <div *ngIf="!showLanguage; else languageComponent" @fadeOut>
        <div class="welcomeText" style="margin-top: 65px;">
          <span style="font-size: 3.5rem; color: #FFF; margin-top: 40px; font-weight: 400;">
              Bienvenue
          </span><br/>
          <span style="font-size: 1.6rem; color: #FFF; margin-top: 20px; font-weight: 400;">
              sur le plus grand réseau 4G+ <br> de Polynésie française
          </span>
        </div>
      </div>
      <ng-template #languageComponent>
        <!-- Si eSim -->
        @if(customerFileService.customerFile.esim){
          <div class="welcomeText mt-5">
            <h2 class="text-white mb-4" [innerHTML]="'identification_already_done_title' | translate"></h2>
            <span style="font-size: 1.2rem; color: #FFF; margin-top: 20px; font-weight: 400;" [innerHTML]="'identification_already_done_description' | translate"></span>
          </div>
          <!-- Si consentement valide mais tirage non effectué -->
        } @else if(customerFileService.customerFile.consent && customerFileService.customerFile.consent_date){
          <div class="welcomeText mt-5">
            <h2 class="text-white mb-4" [innerHTML]="'identification_done_draw_not_done_title' | translate"></h2>
            <span style="font-size: 1.2rem; color: #FFF; margin-top: 20px; font-weight: 400;" [innerHTML]="'identification_done_draw_not_done_description' | translate"></span>
          </div>
          <!-- Si consentement valide et tirage effectué -->
        } @else if(customerFileService.customerFile.consent && customerFileService.customerFile.consent_date){
          <div class="welcomeText mt-5">
            <h2 class="text-white mb-4" [innerHTML]="'identification_done_draw_done_title' | translate"></h2>
            <span style="font-size: 1.2rem; color: #FFF; margin-top: 20px; font-weight: 400;" [innerHTML]="'identification_done_draw_done_description' | translate"></span>
            <button></button>
            <a [routerLink]="'/'" class="btn btn-vini-primary btn-lg rounded-pill w-100 my-4 shadow">
              {{ 'button_restart_identification' | translate }}
            </a>
          </div>
        } @else {
          <div id="languageComponent">
            <app-language (languageSelected)="onLanguageSelected($event)" class="text-white"></app-language>
          </div>
        }
      </ng-template>
    </div>
  </div>
</div>
