import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { importProvidersFrom } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { FormIdComponent } from './pages/identification/form-id/form-id.component';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { NamirialClickWrapComponent } from './pages/identification/namirial-click-wrap/namirial-click-wrap.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';


import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { IdentificationComponent } from './pages/identification/identification.component';
import { ScanQrcodeComponent } from './pages/identification/scan-qrcode/scan-qrcode.component';
import { GreatingsComponent } from './pages/greatings/greatings.component';
import { OnatiTranslateLoader } from './shared/loader/onati-translate-loader';

export function playerFactory() {
  return player;
}


import { environment } from '../environments/environment';
import { RevendeurComponent } from './pages/revendeur/revendeur.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateParserFormatter } from './shared/adaptator/date-parser-formatter';
import { JeuComponent } from './pages/jeu/jeu.component';

// Fonction de chargement des fichiers de traduction
export function HttpLoaderFactory(http: HttpClient) {
  return new OnatiTranslateLoader(http, environment.apiUrl);
}

@NgModule({
  declarations: [
    AppComponent,
    FormIdComponent,
    LoginComponent,
    NamirialClickWrapComponent,
    MentionsLegalesComponent,
    OnboardingComponent,
    IdentificationComponent,
    ScanQrcodeComponent,
    GreatingsComponent,
    RevendeurComponent,
    JeuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration(),
    CookieService,
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })),
    provideLottieOptions({
      player: playerFactory,
    }),

    { provide: NgbDateParserFormatter, useClass: DateParserFormatter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
