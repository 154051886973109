import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {
  // Convertir l'objet NgbDateStruct en chaîne au format jj/mm/aaaa
  format(date: NgbDateStruct | null): string {
    return date
      ? `${date.day.toString().padStart(2, '0')}/${date.month
          .toString()
          .padStart(2, '0')}/${date.year}`
      : '';
  }

  // Convertir une chaîne au format jj/mm/aaaa en NgbDateStruct
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const parts = value.trim().split('/');
      return {
        day: parseInt(parts[0], 10),
        month: parseInt(parts[1], 10),
        year: parseInt(parts[2], 10),
      };
    }
    return null;
  }
}
