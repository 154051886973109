<div class="fullscreen d-flex align-items-center justify-content-center bg-vini-primary">
  <div class="close bg-white rounded-circle" [routerLink]="'/identification/' + token">
    <p-button icon="pi pi-times" severity="primary" [text]="true" [plain]="true" [style]="{color: '#dc001c'}" />
  </div>
  <div class="container" style="max-width: 400px;">
    <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onScanSuccess($event)" [hidden]="step < 3"></ngx-scanner-qrcode>
    <a class="btn btn-outline-light btn-lg text-white rounded-pill w-100 mt-5" [hidden]="step < 4" (click)="action.stop()" [routerLink]="'/identification'">
      {{ 'button_cancel' | translate }}
    </a>

    <div [hidden]="step > 2">
      <!-- STEP 1 -->
      <div id="step1" class="step" *ngIf="step == 1">
        <div class="step-title text-white">
          <span class="number">1</span> {{ 'scan_qrcode_title_1' | translate }}
        </div>
        <p class="text-white" [innerHTML]="'scan_qrcode_description_1' | translate"></p>
        <img src="assets/images/scan-helper-2.png" alt="scan-helper" width="100%" class="rounded">
        <a class="btn btn-outline-light btn-lg text-white rounded-pill w-100 mt-5" (click)="step = 2;">
          {{ 'button_continue' | translate }}
        </a>
      </div>
      <!-- STEP 2 -->
      <div id="step2" class="step" *ngIf="step == 2">
        <div class="step-title text-white">
          <span class="number">2</span>{{ 'scan_qrcode_title_2' | translate }}
        </div>
        <img src="assets/images/scan-helper-3.png" alt="scan-helper" width="100%" class="rounded">
        <a class="btn btn-outline-light btn-lg text-white rounded-pill w-100 mt-5" (click)="step = 4; action.start()">
          {{ 'button_scan' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
