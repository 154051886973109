import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {
  env = environment;
  constructor() { }

  /**
   * Custom console.log function
   * @param func - is the name of the function where the debug is call
   * @param color - is the color of the debug (has default black)
   * @param message - is the message of the function debug ( can be null)
   * @param data - is the data of the debug (can be null)
   * @example
   * color value can be
   * g = green
   * b = dodgerBlue
   * r = red
   * o = orange
   * default = black
   * this.consol.debug("getById", "g", "this is a message", data);
   * @return void
   */
  debug(func, color = 'i', message = null, data = null, bg = null) {
    if(this.env.debug) {
      color = color || "black";

      switch (color) {
        case "g":
          color = "Green";
          break;
        case "b":
          color = "DodgerBlue";
          break;
        case "r":
          color = "Red";
          break;
        case "o":
          color = "Orange";
          break;
        case "w":
          color = "white";
          break;
        default:
          color = color;
      }

      if(data) console.log("%c" + func + " ------- " + message, (bg ? 'background:' + bg + ';':'') + "color:" + color , data);
      else console.log("%c" + func + " ------- " + message, (bg ? 'background:red' + bg + ';':'') + "color:" + color);
    }
  }
}



