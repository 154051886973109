<p-sidebar [visible]="sidebarService.sidebarVisible()" position="right" (visibleChange)="onSidebarVisibleChange($event)">
  <ul class="list-group list-group-flush" *ngIf="!isRevendeur">
    <a class="pointer list-group-item fs-5" [routerLink]="'/'" (click)="sidebarService.hide()">{{ 'sidebar_link_home_label' | translate }}</a>
    <a class="pointer list-group-item fs-5" (click)="showLanguageForm.emit(true);sidebarService.hide()">{{ 'sidebar_link_language_label' | translate }}</a>
    <a class="pointer list-group-item fs-5" [href]="'https://www.vini.pf/politique-de-protection-des-donnees-personnelles'" target="_blank">{{ 'sidebar_link_rgpd_label' | translate }}</a>
    <a class="pointer list-group-item fs-5" [href]="'https://www.vini.pf/mentions-legales'">{{ 'sidebar_link_legal_notice_label' | translate }}</a>
    <a class="pointer list-group-item fs-5">{{ 'sidebar_link_contact_label' | translate }}</a>
  </ul>
  <ul class="list-group list-group-flush" *ngIf="isRevendeur">
    <a class="pointer list-group-item fs-5" (click)="authService.logout()">{{ 'Déconnexion' | translate }}</a>
  </ul>
</p-sidebar>
